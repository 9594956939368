import * as React from "react"
import Hero from "../roaster/hero"
import Oppurtunity from "../roaster/oppurtunity"
import Explore from "./explore"
import Experience from "../roaster/experience"
import Join from "../roaster/join"
import Relationship from "./relationship"
import TeamWork from "../roaster/teamwork"

import heroimage from "../../assets/images/hospitalityhero.jpg"
import exp1 from "../../assets/images/explore.png"
import exp2 from "../../assets/images/white-i-phone.svg"
import exp3 from "../../assets/images/shop.jpg"
import productImage from "../../assets/images/product.jpg"
import Fazenda from "../../assets/images/Fazenda_California.webp"
import bulb from "../../assets/images/icons/bulb.svg"
import globe from "../../assets/images/icons/globe.svg"
import thumb from "../../assets/images/icons/thumb.svg"
import market from "../../assets/images/icons/market.svg"
import time from "../../assets/images/icons/time.svg"
import communication from "../../assets/images/icons/invention.svg"
import chart4 from "../../assets/images/icons/chart.svg"

const Hospitality = ({ pageContext: { translate } }) => {
  const dataOppurtunity = [
    {
      icon: globe,
      title: translate.t?.global_movement,
      description: translate.t?.adv_deluxe_attract,
    },
    {
      icon: thumb,
      title: translate.t?.advantage,
      description: translate.t?.competitive_advantage,
    },
    {
      icon: bulb,
      title: translate.t?.experience,
      description: translate.t?.engaging_experience_customers,
    },
  ]
  const dataExplore = [
    {
      img: exp1,
      pagename: translate.t?.wholesale,
      title: translate.t?.subscribe_buy_freshly,
      description: translate.t?.shop_ecomm_discounts,
    },
    {
      img: exp2,
      pagename: translate.t?.outlet_portal_public,
      title: translate.t?.add_value_verified_information,
      description: translate.t?.with_our_qr_in_shops,
    },
    {
      img: exp3,
      childImage: productImage,
      pagename: translate.t?.building_relationships,
      title: translate.t?.make_informed_decisions,
      description: translate.t?.be_a_smart_buyer,
    },
  ]

  const dataTeam = [
    {
      icon: time,
      title: translate.t?.time_investment,
      description: translate.t?.spend_time_understanding_values,
    },
    {
      icon: communication,
      title: translate.t?.reinvention,
      description: translate.t?.participate_improve_community,
    },
    {
      icon: market,
      title: translate.t?.marketing_communication,
      description: translate.t?.spread_word_mission_transparency,
    },
    {
      icon: chart4,
      title: translate.t?.estate_attributes,
      description: translate.t?.roasters_adopt_onboard,
    },
  ]

  const relationData = [
    {
      name: translate.t?.small_business,

      title: "",
      lister: [
        {
          value: translate.t?.indulge_customers_immersive,
        },
        {
          value: translate.t?.attract_offering_specialized_brands,
        },
        {
          value: translate.t?.be_quality_driven_better_reasons,
        },
      ],
    },
    {
      name: translate.t?.franchise,

      title: "",
      lister: [
        {
          value: translate.t?.indulge_customers_immersive,
        },
        {
          value: translate.t?.attract_offering_specialized_brands,
        },
        {
          value: translate.t?.be_quality_driven_better_reasons,
        },
      ],
    },
    {
      name: translate.t?.retail,

      title: "",
      lister: [
        {
          value: translate.t?.indulge_customers_immersive,
        },
        {
          value: translate.t?.attract_offering_specialized_brands,
        },
        {
          value: translate.t?.be_quality_driven_better_reasons,
        },
      ],
    },
    {
      name: translate.t?.hotel_lodging,
      title: "",
      lister: [
        {
          value: translate.t?.indulge_customers_immersive,
        },
        {
          value: translate.t?.attract_offering_specialized_brands,
        },
        {
          value: translate.t?.be_quality_driven_better_reasons,
        },
      ],
    },
  ]
  return (
    <>
      {
        <div className="hospitality_page">
          <div className="roaster_page">
            <Hero
            pageContext={{ translate }}
              pagename={translate.t?.eraofwe_for_retail}
              title={translate.t?.connect_people_behind_each_cup}
              description={translate.t?.home_hospitality_description}
              btntext={translate.t?.book_a_demo}
              btnLink=""
              img={heroimage}
              model="contact"
            />
            <Oppurtunity
            pageContext={{ translate }}
              heading={translate.t?.new_opportunities}
              data={dataOppurtunity}
            />
            <Experience
            pageContext={{ translate }}
              title={translate.t?.members_love_talk}
              btnText={translate.t?.view_our_community}
              btnLink={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }community/`}
              img={Fazenda}
              name="Flavia and Luiz Saldanha Rodrigues "
              designation="Fazenda California"
              pagetitle=""
              description={translate.t?.public_quote_1}
            />
            <Relationship
              pageContext={{ translate }}
              title={translate.t?.we_empower_farmers}
              description={translate.t?.host_coffee_growers}
              data={relationData}
              btnText={translate.t?.download_whitepaper}
              btnLink=""
            />
            <Explore
              pageContext={{ translate }}
              title={translate.t?.be_part_beyond_trade}
              btnText={translate.t?.book_a_demo}
              btnLink=""
              data={dataExplore}
              model="contact"
            />
            <div className="facilitator estate">
              <TeamWork
                pageContext={{ translate }}
                heading={translate.t?.great_teamwork_commitment}
                btnText={translate.t?.download_whitepaper}
                btnLink=""
                data={dataTeam}
                modal="download"
                flex={true}
              />
            </div>
            <div className="joiner-second">
              <Join pageContext={{ translate }} />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Hospitality
