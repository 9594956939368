import * as React from "react"
import { Link } from "gatsby"
import Whitepaper from "../models/whitepaper"
import logo1 from "../../assets/images/logos/artboard-logo.svg"
import logo2 from "../../assets/images/logos/green-logo.svg"
import logo3 from "../../assets/images/logos/copper-flat-logo.svg"
import logo4 from "../../assets/images/logos/california-logo.svg"
import logo5 from "../../assets/images/logos/ama-logo.svg"

const Explore = ({
  title,
  btnText,
  btnLink,
  data,
  model,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="explore">
          <div className="content">
            <div className="headings">
              <h2 className="heading-h2 semi text-darker">{title}</h2>
              {btnText &&
                (model === "download" ? (
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btnText}
                    styler="whitebtn"
                    title={translate.t?.download_the_whitepaper}
                    description={translate.t?.get_more_insights}
                    type="download"
                    submitBtnText={translate.t?.download_now}
                  />
                ) : model === "contact" ? (
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btnText}
                    styler={"greenbtn"}
                    title={translate.t?.contact_form}
                    description={translate.t?.contact_form_description}
                    type="contact"
                    submitBtnText={translate.t?.submit}
                  />
                ) : (
                  <Link to={btnLink}>
                    <button className="btndefault whitebtn">
                      {btnText}kkk
                    </button>
                  </Link>
                ))}
            </div>
            <div className="list">
              {data.map((content, counter) => {
                return (
                  <div
                    key={counter}
                    className="box"
                    id={counter === 1 ? "special" : ""}
                  >
                    <div className="row box-wrapper">
                      <div
                        className={`col-12 col-md-6 ${
                          counter === 1 ? "col-lg-4" : "col-lg-8"
                        }`}
                      >
                        {counter === 0 && (
                          <div className="logos">
                            <img className="size1" src={logo1} alt="Logo 1" />
                            <img className="size2" src={logo2} alt="Logo 2" />
                            <img className="size3" src={logo3} alt="Logo 3" />
                            <img className="size4" src={logo4} alt="Logo 4" />
                            <img className="size5" src={logo5} alt="Logo 5" />
                          </div>
                        )}
                        {counter > 0 && (
                          <img
                            className="main-image"
                            src={content.img}
                            alt="Explore"
                          />
                        )}
                        {content.childImage && (
                          <img
                            className="child-image"
                            src={content.childImage}
                            alt="Explore_2"
                          ></img>
                        )}
                      </div>
                      <div
                        className={`col-12 col-md-6 d-flex flex-column justify-content-center ${
                          counter === 1 ? "col-lg-8" : "col-lg-4"
                        }`}
                      >
                        <p className="paragraph-p16 bold text-darker">
                          {content.pagename}
                        </p>
                        <h4>{content.title}</h4>
                        <p className="paragraph-p16">{content.description}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Explore
