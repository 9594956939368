import * as React from "react";
import HospitalityPage from "../components/hospitality";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import languages from "../utils/enums/languages";

const Hospitality = ({ location, pageContext: { translate } }) => (
  <Layout secondary pageContext={{ translate }} location={location} SEO={({ data }) =>
    <SEO
      title={data.t?.connect_people_behind_each_cup}
      description={data.t?.hospitality_metad}
      keywords={data.t?.keywords_hospitality}
      languages={[...languages.map((l) => {
        return {
          hrefLang: l || 'en',
          href: `${process.env.GATSBY_SITE_URL}${l ? '/' + l + '/' : '/'}hospitality/`
        }
      }), {
        hrefLang: 'x-default',
        href: `${process.env.GATSBY_SITE_URL}/hospitality/`
      }]}
    />
  }>
    <>
      {
        (
          <>
            <HospitalityPage pageContext={{ translate }} />
          </>
        )
      }
    </>
  </Layout>
)

export default Hospitality
