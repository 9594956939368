import * as React from "react"
import { useState } from "react"
import TeamWork from "../roaster/teamwork"
import Whitepaper from "../models/whitepaper"

const Relationship = ({
  title,
  description,
  data,
  btnText,
  btnLink,
  pageContext: { translate },
}) => {
  const [active, setActive] = useState("box" + 0 + 1)
  return (
    <>
      {
        <div className="relationship">
          <div className="content HRPadZero">
            <div className="detail">
              <div className="hospitialityTeamwork">
                <TeamWork
                pageContext={{ translate }}
                  heading={title}
                  description={description}
                  className="btndefault whitebtn"
                  btnText={btnText}
                  btnLink=""
                  modal="download"
                />
              </div>
            </div>
            <div className="pointer">
              <div className="left">
                <div className="complete">
                  {data.map((content, counter) => (
                    <div key={counter}>
                      <div
                        className={
                          active === "box" + counter + 1 ? "active box" : "box"
                        }
                        onClick={() => setActive("box" + counter + 1)}
                      >
                        <h4>{content.name}</h4>
                      </div>
                      {data.map((data1, counter1) => {
                        return (
                          active === "box" + counter1 + 1 &&
                          "box" + counter + 1 === "box" + counter1 + 1 && (
                            <div key={counter1} className="mobile-img">
                              <p className="paragraph-p1 text-medium  bold">
                                {data1.title}
                              </p>
                              <ul>
                                {data1?.lister.map((contenter, count) => (
                                  <li key={count} className="paragraph-p16">
                                    {contenter.value}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )
                        )
                      })}
                    </div>
                  ))}
                  <div className="d-md-none">
                    <Whitepaper
                      pageContext={{ translate }}
                      btnText={btnText}
                      styler="whitebtn"
                      title={translate.t?.download_the_whitepaper}
                      description={translate.t?.get_more_insights}
                      type="download"
                      submitBtnText={translate.t?.download_now}
                    />
                  </div>
                </div>
              </div>
              <div className="right">
                {data.map((data1, counter) => {
                  return (
                    active === "box" + counter + 1 && (
                      <div key={counter}>
                        <p className="paragraph-p1 text-medium  bold">
                          {data1.title}
                        </p>
                        <ul>
                          {data1?.lister.map((contenter, index) => (
                            <li key={index} className="paragraph-p16">
                              {contenter.value}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  )
                })}
                <div className="d-lg-none">
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btnText}
                    styler="whitebtn"
                    title={translate.t?.download_the_whitepaper}
                    description={translate.t?.get_more_insights}
                    type="download"
                    submitBtnText={translate.t?.download_now}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Relationship
